<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).px-4
  Form(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SailorInternationalPassport',
  components: {
    Form: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'number', text: this.$t('number'), sortable: true },
        { value: 'fullNameEng', text: this.$t('nameEN'), sortable: false },
        { value: 'date_start', text: this.$t('dateIssue'), sortable: true },
        { value: 'date_end', text: this.$t('dateTermValid'), sortable: true },
        { value: 'status_document', text: this.$t('status'), sortable: true },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({
            name: 'passports-international-info',
            params: { ...this.$route.params, documentID: item.id },
            query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.sailorForeignPassports,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getSailorForeignPassports']),
    async getData (params) {
      this.getSailorForeignPassports({ ...this.$route.params, params })
    }
  }
}
</script>
